import React from 'react';

import { App, MainContainer } from '@components';

const NotFoundPage = () => (
  <App>
    <MainContainer>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </MainContainer >
  </App >
);

export default NotFoundPage;
